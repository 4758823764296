import "./index.scss"
import React, {useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle} from 'react';
import logoImg from "img/first/logo.png"
import arrowImg from "img/first/arrow.png"
import redIcon from "img/first/red-decorate.png"
import greenIcon from "img/first/green-decorate.png"
import smallLogoImg from "img/first/small-logo.png"
import {gsap} from 'gsap';
import {BgPage} from "src/view/first/bg.tsx";

type ChildProps = {};

export const FirstPage = forwardRef((props: ChildProps, ref) => {
    const [coords, setCoords] = useState({x: 0, y: 0});
    // const [hovered, setHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const requestRef: any = useRef();
    const animateLineRef: any = useRef();
    const hovered: any = useRef();
    const isPc: any = useRef();
    const handleMouseMove = useCallback((e: any) => {
        if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
        }
        const handleUpdate = () => {
            const rect = e.target.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2;
            const centerY = rect.top + rect.height / 2;
            const x = e.clientX - centerX;
            const y = e.clientY - centerY;
            setCoords({x, y});
        };
        requestRef.current = requestAnimationFrame(handleUpdate);
    }, []);

    const handleMouseEnter = () => hovered.current = true;
    const handleMouseLeave = () => {
        hovered.current = false;
        setCoords({x: 0, y: 0});
    };
    useImperativeHandle(ref, () => ({
        startAnimation() {
            handleResize()
            hovered.current = false
            setTimeout(() => {
                if (animateLineRef.current) {
                    animateLineRef.current.restart()
                    return
                }
                animateLineRef.current = gsap.timeline();
                const list = document.querySelectorAll('.letter')
                const innerCircle = document.querySelectorAll('.inner-circle')
                const innerWord = document.querySelectorAll('.inner-word')
                document.querySelectorAll('.letter').forEach((letter, index) => {
                    animateLineRef.current.to(letter, {
                        opacity: 1,
                        // y: 0, // Move to original position
                        duration: 0.3,
                        ease: 'power2.out'
                    }, index * 0.2); // Stagger each letter's animation
                });

                const box = document.querySelectorAll('.box')
                animateLineRef.current.to(box, {
                    duration: 1,
                    opacity: 1,
                    ease: 'easeInOut'
                }, '<');
                const phoneCircle = document.querySelectorAll('.phone-circle')
                if (phoneCircle.length > 0) {
                    animateLineRef.current.to(phoneCircle, {
                        duration: 1,
                        opacity: 1,
                        ease: 'easeInOut'
                    }, '<');
                }
                // 先是边框旋转两圈，然后边框消失，文字和圈出现
                // const spinner = document.querySelectorAll('.spinner')
                // animateLineRef.current.to(spinner, {
                //     opacity: 1,
                //     duration: 1, // 动画持续时间
                //     rotation: 720, // 旋转两圈
                //     ease: "linear", // 线性过渡
                //     repeat: 0, // 不重复
                // }, '<');
                // animateLineRef.current.to(spinner, {
                //     opacity: 0,
                //     duration: 0.01, // 动画持续时间
                //     ease: "linear", // 线性过渡
                //     repeat: 0, // 不重复
                // }, '>');
                animateLineRef.current.to([innerCircle, innerWord], {
                    opacity: 1,
                    duration: 1,
                    ease: 'easeInOut'
                }, '<'); // "<"在上个动画开始，">"在上个动画结束
            })
        },
        removeAnimation() {
            if (animateLineRef.current) {
                const elements = document.querySelectorAll(".animated-element");
                gsap.set(elements, {opacity: 0});
                animateLineRef.current.kill();
            }
        }
    }))

    const mouseEnter = (className: any, e: any) => {
        const element = e.target.classList
        element.remove(className)
    }
    const mouseLeave = (className: any, e: any) => {
        const element = e.target.classList
        element.add(className)
    }

    const handleResize = () => {
        if (window.innerWidth > 750) {
            isPc.current = true
        } else {
            isPc.current = false
        }
    };

    const clickLaunchApp = ()=> {
        let url = 'https://factory.cellula.life/'
        window.open(url, '_blank')
    }

    useEffect(() => {
        handleResize()
        // 添加窗口尺寸变化的监听器
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className={'first-page'}>
            {/*<div className={'bg-wrap'}>*/}
            {/*    <BgPage></BgPage>*/}
            {/*</div>*/}
            <div className={'logo-wrap'}>
                <img src={logoImg} className={'logo'} alt=""/>
                <div className="button" onClick={clickLaunchApp}>Launch APP</div>
            </div>
            <div className={'first-content'}>
                <div className={'center-word'}>
                    {
                        isPc.current ?
                            <div className={'top-word'}>
                                <div className={'yellow'}>
                                    <span className={'letter animated-element'}>Cellula</span>
                                    <span>&nbsp;</span>
                                </div>
                                <div className={'white'}>
                                    <span className={'letter animated-element'}>-</span>
                                    <span>&nbsp;</span>
                                    <span className={'letter animated-element'}>Programmable</span>
                                </div>
                            </div>
                            :
                            <div>
                                <div className={'top-word'}>
                                    <div className={'yellow'}>
                                        <span className={'letter animated-element'}>Cellula</span>
                                        <span>&nbsp;</span>
                                    </div>
                                    <div className={'white'}>
                                        <span className={'letter animated-element'}>-</span>
                                    </div>
                                </div>
                                <div className={'top-word'}>
                                    <div className={'white'}>
                                        <span className={'letter animated-element'}>Programmable</span>
                                    </div>
                                </div>
                            </div>
                    }
                    <div className={'bottom-word'}>
                        <div className={'blue'}>
                            <span className={'letter animated-element'}>Incentive</span>
                        </div>
                        <span>&nbsp;</span>
                        <div className={'white'}>
                            <span className={'letter animated-element'}>Layer</span>
                        </div>
                    </div>
                </div>

                <div className={`box upDown virtual animated-element ${isActive ? 'active' : ''} `}>Virtual Mining
                </div>
                <div className={`box upDown fair animated-element ${isActive ? 'active' : ''}`}>Fair Asset Distribution
                </div>
                <div className={`box leftAndRight liquidity animated-element ${isActive ? 'active' : ''}`}>Liquidity
                    Guidance
                </div>
                <img className={'box redIcon animated-element'} src={redIcon} alt=""/>
                <img className={'box greenIcon animated-element'} src={greenIcon} alt=""/>
            </div>

            {
                isPc.current ? <div className={'first-footer'}>
                    <div
                        className="outer-circle"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onMouseMove={handleMouseMove}
                        onClick={() => (window as any)["fullpage_api"].moveTo(2)}
                    >
                        <div
                            className="inner-circle circle1 animated-element"
                            style={{
                                transform: hovered.current
                                    ? `translate(${coords.x * 1}px, ${coords.y * 1}px)`
                                    : 'none',
                            }}
                        >
                        </div>
                        <div className="spinner"></div>
                        {/* 这里判断hovered.current是否为true，即鼠标有没有放到圆上 */}
                        {/* 在hovered.current为true或者false时，都要展示箭头图片和inner-word，通过visibility: hidden去控制隐藏 */}
                        {
                            hovered.current ?
                                <div>
                                    <img
                                        className="arrow-img" src={arrowImg} alt=""
                                        style={{
                                            transform: hovered.current
                                                ? `translate(${coords.x * 0.8}px, ${coords.y * 0.8}px)`
                                                : 'none',
                                        }}/>
                                    <div className="inner-word animated-element" style={{visibility: 'hidden'}}>
                                        <img src={smallLogoImg} alt=""/>
                                    </div>
                                </div>
                                :
                                <div>
                                    <img
                                        className="arrow-img" src={arrowImg} alt=""
                                        style={{
                                            transform: hovered.current
                                                ? `translate(${coords.x * 0.8}px, ${coords.y * 0.8}px)`
                                                : 'none',
                                            visibility: 'hidden'
                                        }}/>
                                    <div className="inner-word animated-element">
                                        <img src={smallLogoImg} alt=""/>
                                    </div>
                                </div>
                        }
                        <div
                            className="inner-circle circle2 animated-element"
                            style={{
                                transform: hovered.current
                                    ? `translate(${coords.x * 0.5}px, ${coords.y * 0.5}px)`
                                    : 'none',
                            }}
                        />
                    </div>


                </div> : <div className="phone-circle animated-element"
                              onClick={() => (window as any)["fullpage_api"].moveTo(2)}>
                    <img className="small-logo-img" src={smallLogoImg} alt=""/>
                </div>
            }
        </div>
    );
})